import clsx from 'clsx'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import BackIcon from '@ancon/wildcat-ui/shared/icons/arrow-back-long.svg'

import HeadingText from '../../../app/components/HeadingText'
import { ModalHeaderProps } from '../../../app/components/modal/ModalHeader'

import styles from './OrderConfigurationModalHeader.module.scss'

type OrderConfigurationModalHeaderProps = ModalHeaderProps & {
  onBack: () => void
  className?: string
  isSecondStep?: boolean
}

export default function OrderConfigurationModalHeader({
  title,
  onClose,
  onBack,
  className,
  isSecondStep = false,
}: OrderConfigurationModalHeaderProps) {
  function handleOnClickIconButton() {
    if (isSecondStep) {
      onBack()
    } else {
      onClose?.()
    }
  }

  return (
    <div
      className={clsx(
        styles.header,
        {
          [styles.headerWithClose]: !isSecondStep,
          [styles.headerWithBack]: isSecondStep,
        },
        className,
      )}
    >
      <HeadingText as="h2">{title}</HeadingText>
      {(isSecondStep || onClose) && (
        <div
          className={styles.actionButton}
          onClick={handleOnClickIconButton}
          role="presentation"
          aria-label="close"
        >
          {isSecondStep && <BackIcon />}
          {!isSecondStep && onClose && <CloseIcon />}
        </div>
      )}
    </div>
  )
}
