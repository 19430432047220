import {
  convertToLocalTimezone,
  getTimezoneUtcOffsetDifference,
} from '@ancon/wildcat-utils/date'
import moment, { Moment } from 'moment'

export default function getFormattedOrderTimeSlot(
  timestamp: number | Moment | null,
  slotSize = 10,
) {
  if (timestamp) {
    let timeFrom = moment(timestamp)
    let timeTo = timeFrom.clone().add(slotSize, 'minutes')

    const timezoneDiff = getTimezoneUtcOffsetDifference(timeFrom, timeTo)

    // If there is a timezone difference between the two times, convert them to the local timezone
    // This is to ensure that the time slot is displayed correctly in the current timezone
    if (timezoneDiff) {
      timeFrom = convertToLocalTimezone(timeFrom)
      timeTo = convertToLocalTimezone(timeTo)
    }

    return `${timeFrom.format('LT')} - ${timeTo.format('LT')}`
  }

  return null
}
