import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import CalendarIcon from '@ancon/wildcat-ui/shared/icons/calendar-event-outline.svg'
import ClockIcon from '@ancon/wildcat-ui/shared/icons/clock-circle-outline.svg'
import moment from 'moment'
import { ServiceTime } from '@ancon/wildcat-types'

import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import getFormattedOrderTimeSlot from '../../../outlet/utils/getFormattedOrderTimeSlot'

import styles from './OrderConfigureTimeInfo.module.scss'

type OrderConfigureTimeInfoProps = {
  serviceTime: ServiceTime
  onClickScheduleOrder: () => void
}

export default function OrderConfigureTimeInfo({
  serviceTime,
  onClickScheduleOrder,
}: OrderConfigureTimeInfoProps) {
  const { t } = useTranslation('common')
  const pickerTimeMoment = serviceTime?.time
    ? moment(serviceTime.time)
    : undefined

  return (
    <>
      <div className={clsx(styles.row, styles.spaceBetween)}>
        <div className={styles.innerRow}>
          <CalendarIcon />
          <BodyText fontSize="1.6rem">
            {pickerTimeMoment
              ? `${pickerTimeMoment.calendar({
                  sameDay: `[${t('today')}]`,
                  nextDay: `[${t('tomorrow')}]`,
                  nextWeek: 'dddd',
                })}, ${pickerTimeMoment.format('D MMM')}`
              : t('newOrderTimeConfigureModal.date')}
          </BodyText>
        </div>
        <Button variant="secondary" onClick={onClickScheduleOrder}>
          {serviceTime?.time
            ? t('newOrderTimeConfigureModal.change')
            : t('newOrderTimeConfigureModal.schedule')}
        </Button>
      </div>
      <div className={styles.row}>
        <ClockIcon />
        <BodyText fontSize="1.6rem">
          {pickerTimeMoment
            ? getFormattedOrderTimeSlot(pickerTimeMoment)
            : t('newOrderTimeConfigureModal.time')}
        </BodyText>
      </div>
    </>
  )
}
