import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import {
  PickerTime,
  PickerTimeTimezoneTag,
} from '@ancon/wildcat-utils/outlet/types'

import BodyText from '../../app/components/BodyText'

import styles from './OrderTimeSelectionTimezoneTag.module.scss'

type OrderTimeSelectionTimezoneTagProps = {
  pickerTime: PickerTime
}

export default function OrderTimeSelectionTimezoneTag({
  pickerTime,
}: OrderTimeSelectionTimezoneTagProps) {
  const { t } = useTranslation('common')

  const { timezoneTag } = pickerTime

  function getTimezoneTagText() {
    switch (timezoneTag) {
      case PickerTimeTimezoneTag.Summer:
        return t('timezone.summer')
      case PickerTimeTimezoneTag.Standard:
      default:
        return t('timezone.standard')
    }
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.standard]: timezoneTag === PickerTimeTimezoneTag.Standard,
        [styles.summer]: timezoneTag === PickerTimeTimezoneTag.Summer,
      })}
    >
      <BodyText as="span" fontSize="1.2rem">
        {getTimezoneTagText()}
      </BodyText>
    </div>
  )
}
